import React from 'react'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import BannerImage from '../assets/img/page-title-bg.jpg'

import { TbPhoneX } from "react-icons/tb";
import { VscMail } from "react-icons/vsc";
import { IoIosSend } from "react-icons/io";

const Contact = () => {
    return (
        <div>
            <Header />
            <section class="page-title" style={{ backgroundImage: `url(${BannerImage})` }}>
                <div class="auto-container">
                    <div class="title-outer">
                        <h1 class="title">Contact</h1>
                        {/* <ul class="page-breadcrumb">
                            <li><a href="index.html">Home</a></li>
                            <li><a href="index.html">Pages</a></li>
                            <li>Contact</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <section className="contact-details text-left">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6">
                            <div className="contact-details__right">
                                <div className="sec-title"><span className="sub-title">Need any help?</span>
                                    <h3>Get in touch with us</h3>
                                    <div className="text">Lorem ipsum is simply free text available dolor sit amet,
                                        consectetur notted adipisicing elit sed do eiusmod tempor incididunt simply free
                                        ut labore et dolore magna aliqua.</div>
                                </div>
                                <ul className="list-unstyled contact-details__info">
                                    <li>
                                        <div className="icon">
                                            <TbPhoneX color='#FFf' size={30} />
                                            </div>
                                        <div className="text">
                                            <h6>Have any question?</h6>
                                            <a href="tel:980089850"><span>Free</span> +92
                                                (020)-9850</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <VscMail color='#FFf' size={30} />
                                            </div>
                                        <div className="text">
                                            <h6>Write email</h6>
                                            <a href="mailto:needhelp@company.com">needhelp@company.com</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            {/* <span className="lnr-icon-location"></span> */}
                                            <IoIosSend color='#FFf' size={30} />
                                            </div>
                                        <div className="text">
                                            <h6>Visit anytime</h6><span>66 broklyn golden street. New York</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-6">
                            <div className="sec-title"><span className="sub-title">Send us email</span>
                                <h3>Feel free to write</h3>
                            </div>
                            <form id="contact_form2" name="contact_form" className="" action="#" method="post">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mb-3"><label>Name <small>*</small></label><input name="form_name"
                                            className="form-control" type="text" placeholder="Enter Name" /></div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3"><label>Email <small>*</small></label><input name="form_email"
                                            className="form-control required email" type="email"
                                            placeholder="Enter Email" /></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mb-3"><label>Subject <small>*</small></label><input
                                            name="form_subject" className="form-control required" type="text"
                                            placeholder="Enter Subject" /></div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3"><label>Phone</label><input name="form_phone"
                                            className="form-control" type="text" placeholder="Enter Phone" /></div>
                                    </div>
                                </div>
                                <div className="mb-3"><label>Message</label><textarea name="form_message"
                                    className="form-control required" rows="5" placeholder="Enter Message"></textarea>
                                </div>
                                <div className="mb-3"><input name="form_botcheck" className="form-control" type="hidden"
                                    value="" />
                                    <button type="submit" className="theme-btn btn-style-one mr-15"
                                        data-loading-text="Please wait..."><span className="btn-title">Send message</span></button>
                                    <button type="reset"
                                        className="theme-btn btn-style-one"><span className="btn-title">Reset</span></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container-fluid p-0">
                    <div className="row">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3972.085630708663!2d-0.1869646852302701!3d5.603716995223109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1020794fb3c04cd9%3A0x68c867a46b3eb3b8!2sAccra%2C%20Ghana!5e0!3m2!1sen!2sgh!4v1632763524251!5m2!1sen!2sgh"
                            width="100%"
                            height="450"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            title="Ghana Location"
                        ></iframe>
                    </div>
                </div>
            </section>
            <Footer />

        </div>
    )
}

export default Contact