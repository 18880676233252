import React, { useState } from 'react'
import '../assets/css/style/56145fa96f4e7c42.css'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import PageTitleImage from '../assets/img/page-title-bg.jpg'
import Bg1 from '../assets/img/service/bg1.jpg'
import { FaAngleRight } from "react-icons/fa";
import { FaMinus, FaPhoneVolume } from "react-icons/fa6";
import { FaRegFilePdf } from "react-icons/fa";
import { FaCircleCheck } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa";


const ServiceDetails = () => {
    const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  const faqs = [
    'Is my technology allowed on tech?',
    'How to soft launch your business?',
    'How to turn visitors into contributors',
    'How can i find my solutions?'
  ];

    return (
        <div>
            <Header />
            <section className="page-title" style={{ backgroundImage: `url(${PageTitleImage})` }}>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1 className="title">Service Details</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="index.html">Home</a></li>
                            <li><a href="index.html">Pages</a></li>
                            <li>Service Details</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="services-details">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8">
                            <div className="services-details__content">
                                <img src={Bg1} title="Maxon" alt='BgImage' />
                                <h2 className="mt-4">Air Freight</h2>
                                <p>Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui
                                    dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta
                                    sunt explicabo. Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit
                                    amet finibus eros. Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry. Lorem Ipsum has been the ndustry standard dummy text ever since the 1500s,
                                    when an unknown took a galley of type and it to make a type specimen book.</p>
                                <p>When an unknown printer took a galley of type and it to make a type specimen book. It
                                    has survived not only five centuries, but also the leap into typesetting, remaining
                                    essentially unchanged.</p>
                                <div className="content">
                                    <div className="text">
                                        <h3>Our Scope of Work</h3>
                                        <p>Lorem ipsum is simply free text used by copytyping refreshing. Neque porro
                                            est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto
                                            beatae vitae dicta sunt explicabo.</p>
                                    </div>
                                    <div className="feature-list">
                                        <div className="row clearfix">
                                            <div className="col-lg-6 col-md-6 col-sm-12 column">
                                                <div className="single-item">
                                                    <div className="icon-box">
                                                        <FaCircleCheck />
                                                    </div>
                                                    <h6 className="title">Fast home delivery</h6>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 column">
                                                <div className="single-item">
                                                    <div className="icon-box"><FaCircleCheck /></div>
                                                    <h6 className="title">Secure Payments</h6>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 column">
                                                <div className="single-item">
                                                    <div className="icon-box"><FaCircleCheck /></div>
                                                    <h6 className="title">Delivering best products</h6>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 column">
                                                <div className="single-item">
                                                    <div className="icon-box"><FaCircleCheck /></div>
                                                    <h6 className="title">Food Inspections</h6>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 column">
                                                <div className="single-item">
                                                    <div className="icon-box"><FaCircleCheck /></div>
                                                    <h6 className="title">Generator Systems</h6>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 column">
                                                <div className="single-item">
                                                    <div className="icon-box"><FaCircleCheck /></div>
                                                    <h6 className="title">Assessments</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3>FAQ</h3>
                                    <p>Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est
                                        qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae
                                        vitae dicta sunt explicabo.</p>
                                    <ul className="accordion-box wow fadeInRight">
                                        {faqs.map((faq, index) => (
                                            <li className="accordion block " key={index}>
                                                <div className="acc-btn" onClick={() => handleClick(index)}>
                                                    {faq}
                                                    <div className="icon">
                                                        {activeIndex === index ? <FaMinus /> : <FaAngleDown />}
                                                    </div>
                                                </div>
                                                {activeIndex === index && (
                                                    <div className="acc-content current">
                                                        <div className="content">
                                                            <div className="text">There are many variations of passages the majority
                                                                have suffered alteration in some fo injected humour, or
                                                                randomised words believable.</div>
                                                        </div>
                                                    </div>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4">
                            <div className="service-sidebar">
                                <div className="sidebar-widget service-sidebar-single">
                                    <div className="service-sidebar-single-services wow fadeInUp" data-wow-delay="0.1s"
                                        data-wow-duration="1200m">
                                        <div className="title">
                                            <h3>All Services</h3>
                                        </div>
                                        <ul>
                                            <li className="current">
                                                <a href="page-service-details">Air Freight
                                                    <FaAngleRight />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="page-service-details">Sea freight
                                                    <FaAngleRight />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="page-service-details">Road service
                                                    <FaAngleRight />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="page-service-details">Other solution
                                                    <FaAngleRight />
                                                </a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="sidebar-widget service-sidebar-single">
                                    <div className="service-sidebar-single-contact-box text-center wow fadeInUp"
                                        data-wow-delay="0.3s" data-wow-duration="1200m">
                                        <div className="icon" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 auto' }}>
                                            {/* <span className="lnr lnr-icon-phone"></span> */}
                                            <FaPhoneVolume color='#fff' size={30} />
                                        </div>
                                        <div className="title">
                                            <h2>Best <br />Logistics <br />Services</h2>
                                        </div>
                                        <p className="phone"><a href="tel:123456789">+233 2018 75468</a></p>
                                        <p>Call Us Anytime</p>
                                    </div>
                                </div>
                                <div className="sidebar-widget service-sidebar-single mt-3">
                                    <div className="service-sidebar-single-btn wow fadeInUp" data-wow-delay="0.5s"
                                        data-wow-duration="1200m"><a className="theme-btn btn-style-one d-grid"
                                            href="page-service-details#"><span className="btn-title">

                                                <FaRegFilePdf color='#fff' size={30} style={{ marginRight: '1rem' }} />

                                                download pdf file</span></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default ServiceDetails