import React from 'react'
import '../assets/css/style.css'
import ImageLog from '../assets/img/logo.png'
import AddressImage from '../assets/img/icon/address.svg'
import SupportImage from '../assets/img/icon/support.svg'

const Location = () => {
  return (
    <div className="contact_location">
        <div className="container">
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 text-left">
                    <div className="location_left">
                        <div className="logo">
                            <a href="#">
                                <img src={ImageLog} alt="Logo" width={130}/>
                            </a>
                        </div>
                        <ul>
                            <li><a href="#"> <i className="fa fa-facebook"></i> </a></li>
                            <li><a href="#"> <i className="fa fa-twitter"></i> </a></li>
                            <li><a href="#"> <i className="fa fa-youtube"></i> </a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-xl-3 col-md-3">
                    <div className="single_location">
                        <h3> <img src={AddressImage} alt="Address" /> Location</h3>
                        <p>600/D, Kings road, Green lane
                            NewYork-2563</p>
                    </div>
                </div>
                <div className="col-xl-3 col-md-3">
                    <div className="single_location">
                        <h3> <img src={SupportImage} alt="Support" /> Location</h3>
                        <p>+233(0) 201 875 468 <br/>
                            support@massitec.com</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Location