import React from 'react'
import '../assets/css/style/56145fa96f4e7c42.css'
import PageTitleImage from '../assets/img/page-title-bg.jpg'
import ServiceImage1 from '../assets/img/service/service-1.jpg'
import ServiceImage2 from '../assets/img/service/service-2.jpg'
import ServiceImage3 from '../assets/img/service/service-3.jpg'
import ServiceImage8 from '../assets/img/service/service-8.jpg'
import ServiceImage4 from '../assets/img/service/service-8.jpg'
import Header from '../header/Header';
import ChooseUs from '../choose-us/ChooseUs'
import CounterArea from '../counter/Counter'
import Estimation from '../estimation/Estimation'
import Location from '../location/Location'
import Footer from '../footer/Footer'
import { Link } from 'react-router-dom'
import { GiAirplaneDeparture } from "react-icons/gi";
import { CiDeliveryTruck } from "react-icons/ci";
import { GiFishingBoat } from "react-icons/gi";
import { IoSubwayOutline } from "react-icons/io5";


const ServiceGrid = () => {
    return (
        <div>
            <Header />
            <section className="page-title" style={{ backgroundImage: `url(${PageTitleImage})` }}>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1 className="title">Services</h1>
                        {/* <ul style={{color: '#fff', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                            <li><a href="/" style={{color: '#fff'}}>Home</a><FaAngleRight /></li>
                            <li><a href="#">Pages</a></li>
                            <li>Service</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <section className="services-section">
                <div className="anim-icons"><span className="icon icon-wave-line"></span></div>
                <div className="auto-container">
                    <div className="row">
                        <div className="service-block col-xl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                            <div className="inner-box ">
                                <div className="image-box">
                                    <figure className="image">
                                        <Link to={'/service-details'}>
                                            <img src={ServiceImage1} title="Maxon" alt='Service' /></Link></figure>
                                </div>
                                <div className="content-box text-left">
                                    <GiAirplaneDeparture className='icon' />
                                    <span
                                        className="sub-title">01 Service</span>
                                    <h4 className="title"><Link to={'service-details'}>Air freight</Link></h4>
                                    <div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing</div>
                                </div>
                            </div>
                        </div>
                        <div className="service-block col-xl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                            <div className="inner-box text-left">
                                <div className="image-box">
                                    <figure className="image"><Link to={'service-details'}>
                                        <img src={ServiceImage2} title="Maxon" alt='Service' /></Link></figure>
                                </div>
                                <div className="content-box">
                                    <GiFishingBoat className='icon' />
                                    <span
                                        className="sub-title">02 Service</span>
                                    <h4 className="title"><Link to={'service-details'}>Sea freight</Link></h4>
                                    <div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing</div>
                                </div>
                            </div>
                        </div>
                        <div className="service-block col-xl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                            <div className="inner-box text-left">
                                <div className="image-box">
                                    <figure className="image"><Link to={'service-details'}>
                                        <img src={ServiceImage3} title="Maxon" alt='Service' /></Link></figure>
                                </div>
                                <div className="content-box">
                                    <CiDeliveryTruck className='icon' />
                                    <span
                                        className="sub-title">03 Service</span>
                                    <h4 className="title"><Link to={'service-details'}>Road service</Link></h4>
                                    <div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing</div>
                                </div>
                            </div>
                        </div>
                        <div className="service-block col-xl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                            <div className="inner-box text-left">
                                <div className="image-box">
                                    <figure className="image"><Link to={'service-details'}><img
                                        src={ServiceImage8} title="Maxon" alt='Service' /></Link></figure>
                                </div>
                                <div className="content-box">
                                    <IoSubwayOutline className='icon' />
                                    <span
                                        className="sub-title">04 Service</span>
                                    <h4 className="title"><Link to={'service-details'}>Rail freight</Link></h4>
                                    <div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing</div>
                                </div>
                            </div>
                        </div>
                        <div className="service-block col-xl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                            <div className="inner-box text-left">
                                <div className="image-box">
                                    <figure className="image"><Link to={'service-details'}><img
                                        src={ServiceImage1} title="Maxon" alt='Service' /></Link></figure>
                                </div>
                                <div className="content-box">
                                    <GiAirplaneDeparture className='icon' />
                                    <span
                                        className="sub-title">05 Service</span>
                                    <h4 className="title"><Link to={'service-details'}>Supply Chain</Link></h4>
                                    <div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing</div>
                                </div>
                            </div>
                        </div>
                        <div className="service-block col-xl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                            <div className="inner-box text-left">
                                <div className="image-box">
                                    <figure className="image"><Link to={'service-details'}><img
                                        src={ServiceImage2} title="Maxon" alt='Service' /></Link></figure>
                                </div>
                                <div className="content-box">
                                    <i className="icon flaticon-airplane-2"></i>
                                    <GiFishingBoat className='icon' />
                                    <span
                                        className="sub-title">06 Service</span>
                                    <h4 className="title"><Link to={'service-details'}>Packaging</Link></h4>
                                    <div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing</div>
                                </div>
                            </div>
                        </div>
                        <div className="service-block col-xl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                            <div className="inner-box ">
                                <div className="image-box text-left">
                                    <figure className="image"><Link to={'service-details'}><img
                                        src={ServiceImage4} title="Maxon" alt='Service' /></Link></figure>
                                </div>
                                <div className="content-box">
                                    <IoSubwayOutline className='icon' />
                                    <span
                                    className="sub-title">07 Service</span>
                                    <h4 className="title"><Link to={'service-details'}>Warehousing</Link></h4>
                                    <div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing</div>
                                </div>
                            </div>
                        </div>
                        <div className="service-block col-xl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                            <div className="inner-box text-left">
                                <div className="image-box">
                                    <figure className="image"><Link to={'service-details'}><img
                                        src={ServiceImage3} title="Maxon" alt='Service' /></Link></figure>
                                </div>
                                <div className="content-box">
                                    <CiDeliveryTruck className='icon' />
                                    <span className="sub-title">08 Service</span>
                                    <h4 className="title"><Link to={'service-details'}>Refund Product</Link></h4>
                                    <div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ChooseUs />
            <CounterArea />
            <Estimation />
            <Location />
            <Footer />
        </div>
    )
}

export default ServiceGrid