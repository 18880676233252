import React from 'react'
import '../assets/css/style.css'
import ImageLogo from '../assets/img/logo.png'
import { Link } from 'react-router-dom'
import { FaEnvelope } from "react-icons/fa";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { CiSearch } from "react-icons/ci";


const Header = () => {
    return (
        <div>
            <header>
                <div className="header-area ">
                    <div className="header-top_area d-none d-lg-block">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-4 col-lg-4">
                                    <div className="logo">
                                        <a href="index.html">
                                            <img src={ImageLogo} alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-xl-8 col-md-8">
                                    <div className="header_right d-flex align-items-center">
                                        <div className="short_contact_list">
                                            <ul>
                                                <li><a href="#">
                                                    <FaEnvelope color='#FF3414' size={20} style={{ marginRight: '0.2rem' }} /> contact@massitec.com</a></li>
                                                <li><a href="#">
                                                    <MdOutlinePhoneInTalk color='#FF3414' size={20} style={{ marginRight: '0.2rem' }} /> 1601-609 6780</a></li>
                                            </ul>
                                        </div>

                                        <div className="book_btn d-none d-lg-block">
                                            <a className="boxed-btn3-line" href="#">Get Free Quote</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="sticky-header" className="main-header-area">
                        <div className="container">
                            <div className="header_bottom_border">
                                <div className="row align-items-center">
                                    <div className="col-12 d-block d-lg-none">
                                        <div className="logo">
                                            <a href="index.html">
                                                <img src={ImageLogo} alt="" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-xl-9 col-lg-9">
                                        <div className="main-menu  d-none d-lg-block">
                                            <nav>
                                                <ul id="navigation">
                                                    <li><Link to={'/'}>home</Link></li>
                                                    <li><Link to={'/services'}>Services</Link></li>
                                                    <li><Link to={'/about'}>about</Link></li>
                                                    <li><Link to={'/contact'}>Contact</Link></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 d-none d-lg-block">
                                        <div className="Appointment justify-content-end">
                                            <div className="search_btn" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <a data-toggle="modal" data-target="#exampleModalCenter" href="#">
                                                    <CiSearch color='#fff' size={20} style={{marginBottom: '3px'}}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="mobile_menu d-block d-lg-none"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header