import React from 'react'
import '../assets/css/style.css'

import AboutUs from '../assets/img/about/about.png'

const ChooseUs = () => {
  return (
    <div className="chose_area ">
        <div className="container">
            <div className="features_main_wrap">
                <div className="row  align-items-center">
                    <div className="col-xl-5 col-lg-5 col-md-6">
                        <div className="about_image">
                            <img src={AboutUs} alt="About" />
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6" style={{marginLeft: '3rem'}}>
                        <div className="features_info text-left">
                            <h3>Why Choose Us?</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt.
                            </p>
                            <ul>
                                <li> Apartments frequently or motionless. </li>
                                <li> Duis aute irure dolor in reprehenderit in voluptate. </li>
                                <li> Voluptatem quia voluptas sit aspernatur.</li>
                            </ul>

                            <div className="about_btn">
                                <a className="boxed-btn3-line" href="about.html">About Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ChooseUs