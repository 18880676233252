import React from 'react'
import '../assets/css/style.css'
import QuoteImage from '../assets/img/svg_icon/quote.svg'
import TestimonialImage from '../assets/img/case/testmonial.png'
import TestImage from '../assets/img/case/1.png'
import { Carousel } from 'antd'


const contentStyle = {
    height: '160px',
    color: '#FF3414',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

const imageQuote = {
    display: 'grid',
    placeItems: 'center',
}
const Testimonial = () => {
    return (
        <div className="testimonial_area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6" style={{ backgroundColor: '#004E64', color: '#fff', paddingTop: '2rem' }}>
                        <Carousel autoplay>
                            <div style={contentStyle}>
                                <div className="single_testmonial text-center">
                                    <div className="quote" style={imageQuote}>
                                        <img src={QuoteImage} alt="" />
                                    </div>
                                    <p>Donec imperdiet congue orci consequat mattis. Donec rutrum porttitor sollicitudin.
                                        Pellentesque id dolor tempor sapien feugiat ultrices.</p>
                                    <div className="testmonial_author">
                                        <div className="thumb">
                                            <img src={TestimonialImage} alt="" />
                                        </div>
                                        <h3>Robert Thomson</h3>
                                        <span>Business Owner</span>
                                    </div>
                                </div>
                            </div>
                            <div style={contentStyle}>
                                <div className="single_testmonial text-center">
                                    <div className="quote" style={imageQuote}>
                                        <img src={QuoteImage} alt="" />
                                    </div>
                                    <p>Donec imperdiet congue orci consequat mattis. Donec rutrum porttitor sollicitudin.
                                        Pellentesque id dolor tempor sapien feugiat ultrices.</p>
                                    <div className="testmonial_author">
                                        <div className="thumb">
                                            <img src={TestimonialImage} alt="" />
                                        </div>
                                        <h3>Robert Thomson</h3>
                                        <span>Business Owner</span>
                                    </div>
                                </div>
                            </div>
                            <div style={contentStyle}>
                                <div className="single_testmonial text-center">
                                    <div className="quote" style={imageQuote}>
                                        <img src={QuoteImage} alt="" />
                                    </div>
                                    <p>Donec imperdiet congue orci consequat mattis. Donec rutrum porttitor sollicitudin.
                                        Pellentesque id dolor tempor sapien feugiat ultrices.</p>
                                    <div className="testmonial_author">
                                        <div className="thumb">
                                            <img src={TestimonialImage} alt="" />
                                        </div>
                                        <h3>Robert Thomson</h3>
                                        <span>Business Owner</span>
                                    </div>
                                </div>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial