import React from 'react'
import '../assets/css/style/56145fa96f4e7c42.css'
import BgImage1 from '../assets/img/background/1.jpg'
import ServiceImage1 from '../assets/img/service/service-1.jpg'
import ServiceImage2 from '../assets/img/service/2.png'
import ServiceImage3 from '../assets/img/service/3.png'
import ServiceImage4 from '../assets/img/service/4.png'
import { GiAirplaneDeparture } from "react-icons/gi";
import { CiDeliveryTruck } from "react-icons/ci";
import { GiAirplaneArrival } from "react-icons/gi";
import { GiFishingBoat } from "react-icons/gi";


const ServiceSection = () => {
    return (
        <div>
            <section className="services-section">
                <div className="bg-image" style={{ backgroundImage: `url(${BgImage1})` }}></div>
                <div className="anim-icons"><span className="icon icon-wave-line"></span></div>
                <div className="auto-container">
                    <div className="sec-title text-center"><span className="sub-title">SPECIALISE IN THE TRANSPORTATION</span>
                        <h2>Specialist logistics services</h2>
                    </div>
                    <div className="row">
                        <div className="service-block col-xl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                            <div className="inner-box ">
                                <div className="image-box">
                                    <figure className="image"><a href="page-service-details"><img
                                        src={ServiceImage1} title="Tronis" alt='Service' /></a></figure>
                                </div>
                                <div className="content-box text-left">
                                    {/* <i className="icon flaticon-airplane-2"></i> */}
                                    <GiAirplaneDeparture className='icon' />
                                    <span className="sub-title">01 Service</span>
                                    <h4 className="title">
                                        <a href="page-service-details">Air freight</a>
                                    </h4>
                                    <div className="text" style={{lineHeight: '1.4rem'}}>Lorem ipsum dolor sit amet, consectetur adipiscing</div>
                                </div>
                            </div>
                        </div>
                        <div className="service-block col-xl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                            <div className="inner-box ">
                                <div className="image-box">
                                    <figure className="image"><a href="page-service-details"><img
                                        src={ServiceImage2} title="Tronis" alt='Service' /></a></figure>
                                </div>
                                <div className="content-box text-left">
                                    {/* <i className="icon flaticon-airplane-2"></i> */}
                                    <CiDeliveryTruck className='icon' />
                                    <span
                                        className="sub-title">02 Service</span>
                                    <h4 className="title"><a href="page-service-details">Sea freight</a></h4>
                                    <div className="text" style={{lineHeight: '1.4rem'}}>Lorem ipsum dolor sit amet, consectetur adipiscing</div>
                                </div>
                            </div>
                        </div>
                        <div className="service-block col-xl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                            <div className="inner-box ">
                                <div className="image-box">
                                    <figure className="image"><a href="page-service-details"><img
                                        src={ServiceImage3} title="Tronis" alt='Service' /></a></figure>
                                </div>
                                <div className="content-box text-left">
                                    <i className="icon flaticon-airplane-2"></i>
                                    <GiAirplaneArrival className='icon' />
                                    <span
                                        className="sub-title">03 Service</span>
                                    <h4 className="title"><a href="page-service-details">Road service</a></h4>
                                    <div className="text" style={{lineHeight: '1.4rem'}}>Lorem ipsum dolor sit amet, consectetur adipiscing</div>
                                </div>
                            </div>
                        </div>
                        <div className="service-block col-xl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                            <div className="inner-box ">
                                <div className="image-box">
                                    <figure className="image"><a href="page-service-details"><img
                                        src={ServiceImage4} title="Tronis" alt='Service' /></a></figure>
                                </div>
                                <div className="content-box text-left">
                                    <i className="icon flaticon-airplane-2"></i>
                                    <GiFishingBoat className='icon' />
                                    <span
                                        className="sub-title">04 Service</span>
                                    <h4 className="title"><a href="page-service-details">Other solution</a></h4>
                                    <div className="text" style={{lineHeight: '1.4rem'}}>Lorem ipsum dolor sit amet, consectetur adipiscing</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ServiceSection