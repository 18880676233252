import React from 'react'
import Header from '../header/Header'
import '../assets/css/style.css'
import ChooseUs from '../choose-us/ChooseUs'
import CounterArea from '../counter/Counter'
import ContactForm from '../contact/ContactForm'
import Estimation from '../estimation/Estimation'
import Testimonial from '../testimonial/Testimonial'
import Location from '../location/Location'
import Footer from '../footer/Footer'
import TransportationArea from '../transportation-area/TransportationArea'
import BannerImage from '../assets/img/page-title-bg.jpg'

const About = () => {
    return (
        <div>
            <Header />
            <section class="page-title" style={{ backgroundImage: `url(${BannerImage})` }}>
                <div class="auto-container">
                    <div class="title-outer">
                        <h1 class="title">Contact</h1>
                        {/* <ul class="page-breadcrumb">
                            <li><a href="index.html">Home</a></li>
                            <li><a href="index.html">Pages</a></li>
                            <li>Contact</li>
                        </ul> */}
                    </div>
                </div>
            </section>

            <ChooseUs />
            <CounterArea />
            <ContactForm />
            <TransportationArea />
            <Testimonial />
            <Estimation />
            <Location />
            <Footer />
        </div>
    )
}

export default About