import React from 'react'
import '../assets/css/style/56145fa96f4e7c42.css'
import '../assets/css/style/e9ea458e815c7b0f.css'
import { BsBoxSeam } from "react-icons/bs";
import { GiKeyCard } from "react-icons/gi";
import { BsBoxes } from "react-icons/bs";

const HowItWork = () => {
    return (
        <div>
            <section className="work-section">
                <div className="anim-icons">
                    <span className="icon icon-dotted-map-2 zoom-one"></span>
                    <span className="icon icon-plane-1 bounce-y"></span>
                </div>
                <div className="auto-container">
                    <div className="sec-title text-center"><span className="sub-title">How It Work</span>
                        <h2>3 easy step to task</h2>
                    </div>
                    <div className="row">
                        <div className="work-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight">
                            <div className="inner-box">
                                <div className="icon-box">
                                    <span className="count">01</span>
                                    {/* <i className="icon flaticon-delivery-box-4"></i> */}
                                    {/* <img src={IconImage} alt='Icon' className='icon' width={100}/> */}
                                    <BsBoxSeam className='icon' />
                                </div>
                                <h4 className="title">Enter your <br />&amp; product details</h4>
                            </div>
                        </div>
                        <div className="work-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight" data-wow-delay="300ms">
                            <div className="inner-box">
                                <div className="icon-box">
                                    <span className="count">02</span>
                                    {/* <i className="icon flaticon-stock-1"></i> */}
                                    <GiKeyCard className='icon' />
                                </div>
                                <h4 className="title">Pay your service <br />charges</h4>
                            </div>
                        </div>
                        <div className="work-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight" data-wow-delay="600ms">
                            <div className="inner-box">
                                <div className="icon-box"><span className="count">03</span>
                                    {/* <i className="icon flaticon-delivery-box-3"></i> */}
                                    <BsBoxes className='icon' />
                                </div>
                                <h4 className="title">Ready to send <br />your goods</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HowItWork