import React from 'react'
import AirPlane from '../assets/img/svg_icon/airplane.png'
import Live from '../assets/img/svg_icon/live.png'
import World from '../assets/img/svg_icon/world.png'

const TransportationArea = () => {
    return (
        <div>
            <div className="transportaion_area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="single_transport">
                                <div className="icon">
                                    <img src={AirPlane} alt="AirPlane" />
                                </div>
                                <h3>Transportation</h3>
                                <p>Esteem spirit temper too say adieus who direct esteem. It look estee luckily or picture
                                    placing drawing.</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="single_transport">
                                <div className="icon">
                                    <img src={Live} alt="Live" />
                                </div>
                                <h3>Live Monitoring</h3>
                                <p>Esteem spirit temper too say adieus who direct esteem. It look estee luckily or picture
                                    placing drawing.</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="single_transport" style={{paddingRight: '5px'}}>
                                <div className="icon">
                                    <img src={World} alt="World" />
                                </div>
                                <h3>Worldwide Service</h3>
                                <p>Esteem spirit temper too say adieus who direct esteem. It look estee luckily or picture
                                    placing drawing.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransportationArea