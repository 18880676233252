import React, { useEffect, useState } from 'react'
import '../assets/css/style.css'


const Counter = ({ target }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if(count < target) {
                setCount(prevCount => prevCount + 1);
            } else {
                clearInterval(interval);
            }
        }, 10);
        return () => clearInterval(interval);
    }, [count, target]);

    return <span className="counter">{count}</span>;
};

const CounterArea = () => {
    return (
        <div className="counter_area">
            <div className="container">
                <div className="offcan_bg">
                    <div className="row">
                        <div className="col-xl-3 col-md-3">
                            <div className="single_counter text-center">
                                <h3> <Counter target={42} /> <span>+</span> </h3>
                                <p>Countries Covered</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3">
                            <div className="single_counter text-center">
                                <h3> <Counter target={97} /> <span>+</span> </h3>
                                <p>Business Success</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3">
                            <div className="single_counter text-center">
                                <h3> <Counter target={2342} /></h3>
                                <p>Happy Client</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3">
                            <div className="single_counter text-center">
                                <h3> <Counter target={3245} /></h3>
                                <p>Business Done</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CounterArea;