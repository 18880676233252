import React from 'react'
import Slider1 from '../assets/img/banner/banner.png'

const Slider = () => {
    return (
        <div>
            <div className="slider_area">
                <div className="single_slider  d-flex align-items-center" style={{backgroundImage: `url(${Slider1})`}}>
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xl-8">
                                <div className="slider_text text-center justify-content-center">
                                    <p>For Personal & Business</p>
                                    <h3>Global Logistic Service
                                        For Business</h3>
                                    <a className="boxed-btn3" href="/services">Our Services</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Slider