import React from 'react'
import Header from '../header/Header'
import Slider from '../slider/Slider'
import TransportationArea from '../transportation-area/TransportationArea'
import ContactForm from '../contact/ContactForm'
import Counter from '../counter/Counter'
import Testimonial from '../testimonial/Testimonial'
import Estimation from '../estimation/Estimation'
import Location from '../location/Location'
import Footer from '../footer/Footer'
import ChooseUs from '../choose-us/ChooseUs'
import HowItWork from '../howitwork/HowItWork'
import ServiceSection from '../services/ServiceSection'

const Main = () => {
  return (
    <div>
      <Header />
      <Slider />
      <TransportationArea />
      <HowItWork />
      <ServiceSection />
      <ContactForm />
      <ChooseUs />
      <Counter />
      <Testimonial />
      <Estimation />
      <Location />
      <Footer />
    </div>
  )
}

export default Main