import React from 'react'
import '../assets/css/style.css'


const Estimation = () => {
  return (
    <div className="Estimate_area overlay">
        <div className="container">
            <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-5">
                    <div className="Estimate_info">
                        <h3>Get free Estimate</h3>
                        <p>Esteem spirit temper too say adieus who direct esteem. It look estee luckily or picture
                            placing.</p>
                        <a href="#" className="boxed-btn3">+10 672 457 356</a>
                    </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-7">
                    <div className="form">
                        <form >
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="input_field">
                                        <input type="text" placeholder="Your name" />
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="input_field">
                                        <input type="email" placeholder="Email" />
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="input_field">
                                        <input type="text" placeholder="Telphone" />
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="input_field">
                                        <input type="text" placeholder="City" />
                                    </div>
                                </div>

                                <div className="col-xl-12">
                                    <div className="input_field">
                                        <textarea placeholder="Message"></textarea>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="input_field">
                                        <button className="boxed-btn3-line" type="submit">Send Estimate</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Estimation